import React, { useCallback, useEffect, useState } from 'react'
import algoliasearch from 'algoliasearch'
import { Breadcrumbs, Container, Hero, Page, SearchInput, SearchResults } from '@components'
import { trackGAEvent } from '@utils/functions'

const searchClient = algoliasearch(
  'ZP25WDVJ8Z',
  'eb31a098ba3698ec426de33053ecfc86'
);

const index = searchClient.initIndex('netlify_381eca73-c75c-48c8-a727-6c8c618f190a_master_all')

const meta = {
  title: 'Pretraga | Protupožarna zaštita - FSB d.o.o.',
  description: '',
}

const getSearchParam = (search) => {
  const urlSearchParams = new URLSearchParams(search)
  const params = Object.fromEntries(urlSearchParams.entries())
  return params.q || ''
}

const SearchPage = ({ location }) => {
  const [results, setResults] = useState()
  const query = getSearchParam(location.search)

  const performSearch = useCallback((val) => {
    if (!val) {
      setResults(null)
      return
    }
    trackGAEvent({ action: 'search', category: 'search', label: val })
    index.search(val)
      .then(res => {
        setResults(res.hits)
      })
  }, [])

  useEffect(() => {
    if (!query) {
      return
    }
    trackGAEvent({ action: 'search', category: 'search', label: query })
    index.search(query)
      .then(res => {
        setResults(res.hits)
      })
  }, [ query ])

  return (
    <Page
      isHomepage
      meta={meta}
    >
      <Breadcrumbs
        links={[
          { link: '/', name: 'Početna'},
          { link: '/pretraga/', name: 'Pretraga'},
        ]}
      />
      <Hero
        slim
        description={meta.description}
        subtitle="Pretraga"
      />
      <Container>
      <SearchInput onChange={performSearch} initialQuery={query} />
      <SearchResults results={results} />
      </Container>
    </Page>
  )
}

export default SearchPage